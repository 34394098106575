.custom_file_input {
    display: inline-block;
    position: relative;
    overflow: hidden;
    padding: 8px 30px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid #1a6aff;
    background-color: #1a6aff;
    border-radius: 5px;
    color: white;
    margin-top: 10px;
    font-size: medium;
    transition: 0.3s ease-in-out;
  }
  
  .custom_file_input input[type="file"] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }


  .profileImage .custom_file_input:hover{
    background-color: #f1f5fc;
    color: #1a6aff;
  }