bodddddy{
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    /* min-height: 100vh; */
    background-color: #6843d1;
  }
  .container{
    position: relative;
  }
  .container .navigation{
    position: relative;
    width: 100px;
    /* height: 420px; */
    height: 590px;
    margin: 8px;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 5px 15px rgb(0, 0, 0,0.2);
    transition: 0.5s;
  }
  .container .navigation.active{
    width: 300px;
  }
  .container .navigation ul{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  .container .navigation ul li{
    position: relative;
    list-style: none;
    width: 100%;
    overflow: hidden;
  }
  .container .navigation ul li:hover{
    background-color: #efefef;
  }
  .container .navigation ul li a{
    position: relative;
    display: block;
    text-decoration: none;
    width: 100%;
    display: flex;
    color: #6843d1;
    font-weight: 500;
  }
  .container .navigation ul li a .icon{
    position: relative;
    display: block;
    min-width: 80px;
    height: 84px;
    line-height: 84px;
    /* text-align: center; */
  }
  .container .navigation ul li a .icon .bi{
    font-size: 26px;
  }
  .container .navigation ul li a .title{
    position: relative;
    display: block;
    height: 84px;
    line-height: 84px;
    white-space: nowrap;
  }
  .toggle{
    position: absolute;
    width: 40px;
    height: 40px;
    cursor: pointer;
    background-color: #fafafa;
    right: 0;
    top: calc(50% - 20px);
    border: 5px solid #6843d1;
    border-radius: 50%;
  }
  .toggle i::before{
    /* font-family: fontAwesome; */
    position: absolute;
    width: 100%;
    height: 100%;
    line-height: 30px;
    text-align: center;
    font-size: 16px;
    font-weight:bold;
    color: #6843d1;
  }
  .toggle .active::before{
    content: '\f30';
  }  