.Doctor_head{
    background-color: #eaeef3;
    padding: 20px;
    width: 100%;
    /* height: 100vh; */
}

.All_in_this{
    width: 100%;
    padding: 20px;
    display: flex;
    margin-top: 10px;
}



/* Profile Card */
.first_column{
    display: inline-block;
    width: 70%;
}
.Profile_card{
    display: flex;
    width: 95%;
    height: 310px;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    border: 1.5px solid #00000017;
}
.Profile_card .img-wrapper{
    overflow: hidden;
    width: 33%;
}
.Profile_card img{
    height: 100%;
    width: 100%;
    border-radius: 8px;
    object-fit: cover;
    transition: all 0.3s ease 0s;
}
.Profile_card img:hover{
    transform: scale(1.1);
}
.profile_detail{
    background-color: white;
    padding-top: 15px;
    margin-left: 15px;
}
.profile_detail h2{
    color: #135d64;
}
.profile_detail .star{
    margin-top: 10px;
    margin-bottom: 10px;
    color: #fcb71b;
}
.profile_detail .star span{
    color: gray;
    margin-left: 10px;
}
.profile_detail h3{
    color: #135d64;
    font-size: 15px;
}
.profile_detail .just_row{
    display: flex;
    margin-top: 30px;
}
.profile_detail .just_row i{
    color: #135d64 ;
}
.profile_detail .just_row h4{
    color: gray ;
    margin-left: 10px;
    font-size: 18px;
}

.profile_detail .just_row_2{
    display: flex;
    margin-top: 20px;
}
.profile_detail .just_row_2 i{
    color: #135d64 ;
}
.profile_detail .just_row_2 h4{
    color: gray ;
    margin-left: 10px;
    font-size: 20px;
}
/*  */






/* Chanebtn Card */
.chanebtn_card{
    width: 95%;
    background-color: white;
    margin-top: 20px;
    height: 80px;
    border: 1.5px solid #00000017;
    display: flex;
    align-items: center;
    padding-left: 20px;
    /* justify-content: center; */
    /* padding-left: 18px; */
    /* overflow: auto; */
    /* overflow: hidden;  */
    /* background-image: linear-gradient(90deg,rgba(0,158,251,.8),#fff); */ 
    /* gap: 10px; */
}
.chanebtn_card .about_me_btn_active a{
    width: 110px;
    height: 45px;
    border-radius: 40px;
    color: white;
    background-color: #e91e63;
    display: inline-block;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    box-shadow: 0 5px 15px rgba(32,32,32,.3);
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    transition: 0.3s ease-in-out
}

.chanebtn_card .about_me_btn_inactive a{
    width: 110px;
    height: 45px;
    border-radius: 40px;
    display: inline-block;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    text-decoration: none;
    color: gray;
    font-weight: bold;
    font-size: 16px;
    transition: 0.3s ease-in-out
}
.chanebtn_card .about_me_btn_inactive a:hover{
    background-color: #e91e63;
    color: white;
}
/*  */
 



/* About in changebtn */
.About_in_btn{
  width: 95%;
  margin-top: 15px;
  background-color: white;
  padding: 20px 25px 20px 25px;
  opacity: 100px;
  box-shadow: 0 5px 15px rgba(32,32,32,.3);
  border-radius: 10px;
}
.About_in_btn hr{
  margin-top: 15px;
}
.About_in_btn .numer_licen h3{
  color: #9a7a6b;
  margin-top: 20px;
  font-size: 20px;
}
.About_in_btn .img_licen h3{
  color: #9a7a6b;
  margin-top: 20px;
  font-size: 20px;
}
.About_in_btn .img_licen img{
  width: 250px;
  height: 220px;
  border-radius: 10px;
  margin-top: 10px;
  margin-left: 20px;
  border: 3px solid #1a6aff;
}
.About_in_btn .numer_licen h4{
  margin-left: 15px;
  margin-top: 10px;
  font-size: 20px;
}
.About_in_btn .numer_licen hr{
  margin-bottom: 10px;
}



/* Approved And Disapproved Card */
.Approv_Diss_Card{
    width: 95%;
    margin-top: 15px;
    background-color: white;
    padding: 20px 25px 20px 25px;
    opacity: 100px;
    box-shadow: 0 5px 15px rgba(32,32,32,.3);
    border-radius: 10px;
}
.Approv_Diss_Card hr{
    margin: 20px 0px 20px 0px;
}
.Approv_Diss_Card .two_buttun{
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
}
.Approv_Diss_Card .two_buttun button{
  text-decoration: none;
  color: #444;
  background-color: #e91e63;
  border: 1px solid orange;
  display: inline-block;
  padding: 15px 30px;
  font-weight: bold;
  border-radius: 12px;
  transition: 0.3s ease-in-out;
}
.Approv_Diss_Card .two_buttun button:nth-child(1){
  border-color: #e91e63;
  color: white;
}
.Approv_Diss_Card .two_buttun button:nth-child(2){
  border-color: gray;
  background-color: white;
}
.Approv_Diss_Card .two_buttun button:hover{
  box-shadow: 0px 3px 8px #0003;
}





/* Review Card */
.Review_Card{
    width: 95%;
    margin-top: 15px;
    background-color: white;
    padding: 20px 25px 30px 25px;
    opacity: 100px;
    box-shadow: 0 5px 15px rgba(32,32,32,.3);
    border-radius: 10px;
}
.Review_Card .Noreview{
  display: block;
  text-align: center;
}
.Review_Card .review_all{
    width: 100%;
    margin-top: 30px;
    padding: 15px;
    border-radius: 15px;
    border: 3px solid whitesmoke;
    display: flex;
}
.Review_Card .review_all img{
    width: 110px;
    height: 110px;
    border-radius: 10px;
    object-fit: cover;
}
.Review_Card .review_all .review_details{
    padding: 0px 20px 0px 20px;
    width: 100%;
}
.Review_Card .review_all .review_details p{
    margin-top: 10px;
    color: gray;
}
.Review_Card .review_all .review_details .bottom_deatil_review{
    display: flex;
    margin-top: 10px;
    text-align: center;
    justify-content: space-between;
}
.Review_Card .review_all .review_details .bottom_deatil_review .star{
    color: #fcb71b;
    margin-left: 10px;
}
.Review_Card .review_all .review_details .bottom_deatil_review .rev_approv{
  padding: 5px 10px 5px 10px;
  background-color: #e91e63;
  border-radius: 8px;
  border: 1px solid whitesmoke;
  color: white;
}

/*  */



  /* Setting Update form */
  .Update_seting_form{
    width: 95%;
    margin-top: 15px;
    background-color: white;
    padding-top: 20px;
    padding-bottom: 30px;
    opacity: 100px;
    box-shadow: 0 5px 15px rgba(32,32,32,.3);
    border-radius: 10px;
  }
  .Update_seting_form h2{
    margin-left: 30px;
    margin-bottom: 20px;
  }
  .updat_rst_name{
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 20px;
    flex-wrap: wrap;
    /* background-color: #1a6aff; */
  }
  .updt_width_both{
    width: 94%;
    display: flex;
    justify-content: space-between;
  }
  .update_width_name{
    width: 49%;
    /* background-color: red; */
  }
  .updatsubject_name{
    color: black;
    font-size: 15px;
    margin-bottom: 10px;
  }
  #name{
    width: 100%;
    border: 0.1px solid gray;
    padding: 12px;
    border-radius: 8px;
  }
  #email{
    width: 100%;
    border: 0.1px solid gray;
    padding: 12px;
    border-radius: 8px;
  }
  .updt_seond_subject{
    /* margin: 0px 10px; */
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: #29166f; */
  }
  .updt_width_suject{
    width: 94%;
    /* background-color: #514c65; */
  }
  #subject{
    width: 100%;
    border: 0.11px solid gray;
    padding: 12px;
    border-radius: 8px;
  }
  #message{
    width: 100%;
    height: 160px;
    border: 0.1px solid gray;
    padding: 12px;
    border-radius: 8px;
  }
  .updt_buttun_center{
    margin-top: 25px;
    margin-left: 38px;
    /* text-align: center; */
  }

  .updt_buttun_center .submit{
    padding: 14px 43px;
    border-radius: 5px;
    margin: 5px;
    background-color: #1a6aff;
    color: white;
    border: 1px solid #1a6aff;
    font-size: medium;
    transition: 0.3s ease-in-out;
  }

  .updt_buttun_center #reset{
    background-color: #fda600;
    border-color: #fda600;
  }

  .updt_buttun_center #submite:hover{
    background-color: #f1f5fc;
    color: #1a6aff;
  }

  .updt_buttun_center #reset:hover{
    background-color: #f1f5fc;
    color: #fda600;
  }
  /*  */


/* Delete Card */
  .Delete_Card{
    width: 95%;
    margin-top: 15px;
    background-color: white;
    padding: 20px 25px 20px 25px;
    opacity: 100px;
    box-shadow: 0 5px 15px rgba(32,32,32,.3);
    border-radius: 10px;
  }
  .Delete_Card hr{
    margin: 20px 0px 20px 0px;
  }
  .Delete_Card h4{
    color: gray;
    font-size: 20px;
  }
  .Delete_Card .delete_buttun{
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 10px;
  }
  .Delete_Card .delete_buttun button{
    text-decoration: none;
    color: white;
    margin-top: 10px;
    background-color: #e91e63;
    border: 1px solid orange;
    display: inline-block;
    padding: 15px 60px;
    font-weight: bold;
    border-radius: 12px;
    transition: 0.3s ease-in-out;
  }
  .Delete_Card .delete_buttun button:hover{
    box-shadow: 0px 3px 8px #0003;
  }



/* About Card */
.second_column{
    width: 30%;
    display: inline-block;
}
.About_card{
    width: 100%;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    border: 1.5px solid #00000017;
}
.About_card .about_head{
    width: 100%;
    padding-top: 10px;
    padding-left: 20px;
    border-top: 4px solid #fcb71b;
}
.about_data{
    /* padding-top: 20px; */
    padding-left: 20px;
    padding-right: 20px;
}
.about_data p{
    padding-bottom: 10px;
}
.about_data hr{
    font-weight: 300;
}
.about_dat_row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 15px;
}
.about_dat_row h4{
    color: gray;
    font-size: 15px;
}
.second_column .About_card h3{
    font-size: 15px;
}
.about_last_row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 30px;
    padding-left: 5px;
    padding-right: 5px;
}
.about_last_section{
    text-align: center;
}
.about_last_row .about_last_section h3{
    font-size: 25px;
    color: #5b9bd1;
}
.about_last_row .about_last_section h4{
    font-size: 13px;
    color: #5b9bd1;
    margin-top: 5px;
}
/*  */

/* Address Card */
.Address_Card{
  width: 100%;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  border: 1.5px solid #00000017;
  margin-top: 20px;
  padding-bottom: 20px;
}
.Address_Card .address_head{
    width: 100%;
    padding-top: 10px;
    padding-left: 20px;
    border-top: 4px solid #fcb71b;
}
.Address_Card .address_paragraph{
    text-align: center;
    margin-top: 15px;
}
.Address_Card .address_paragraph p{
    font-size: 20px;
}