.admin_list_category_main{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #eaeef3;
    padding: 40px 0px;
  }
  .primary_table {
    width: 100%;
    /* border: 1px solid #00bcd4; */
    background-color: #efefef33;
    /* padding: 15px; */
    overflow: auto;
    margin-top: 25px;
    /* margin: auto; */
    border-radius: 4px;
   }
   .respons_admin_list_card{
    width: 92%;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 30px 20px 20px;
    box-shadow: 0px 0px 3px rgba(32,32,32,.3);
   }
   .respons_admin_list_card .head_card{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .respons_admin_list_card .head_card button{
      text-decoration: none;
      color: white;
      background-color: #29166f;
      border: 1px solid #29166f;
      display: inline-block;
      padding: 10px 18px;
      font-weight: bold;
      border-radius: 8px;
      transition: 0.3s ease-in-out;
    }
    .respons_admin_list_card .head_card button:hover{
      background-color: white;
      border: 1px solid #29166f;
      color: #29166f;
    }
   .admin_list_tble_tag{
    width: 100%;
    font-size: 13px;
    color: #444;
    white-space: nowrap;
    border-collapse: collapse;
   }
  
   .admin_list_tble_tag th{
    background-color: #00bcd4;
    color: #fff;
    text-transform: uppercase;
  }
  
   .admin_list_tble_tag th{
    padding: 15px;
   }
   .admin_list_tble_tag th, .admin_list_tble_tag td{
     border: 1px solid #00000017;
     padding: 10px 15px;
   }
   .admin_list_tble_tag tr td img{
    display: inline-block;
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 50%;
    border: 4px solid #fff;
    box-shadow: 0px 2px 6px #0003;
   }
  .admin_list_tble_tag .admin_transfe_btn{
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  .admin_list_tble_tag .admin_transfe_btn button{
    text-decoration: none;
    color: #444;
    background-color: #fff;
    border: 1px solid orange;
    display: inline-block;
    padding: 7px 20px;
    font-weight: bold;
    border-radius: 3px;
    transition: 0.3s ease-in-out;
  }
  /* .admin_list_tble_tag .admin_transfe_btn button:nth-child(1){
    border-color: #26a69a;
  }
  .admin_list_tble_tag .admin_transfe_btn button:nth-child(2){
    border-color: orange;
  } */
  
  .admin_list_tble_tag .admin_transfe_btn button:hover{
    box-shadow: 0px 3px 8px #0003;
  }
  
  .admin_list_tble_tag tr{
    background-color: #fff;
    transition: 0.3s ease-in-out;
  }
  .admin_list_tble_tag tr:nth-child(odd){
    /* background-color: rgb(238, 238, 238); */
    background-color: #eeeeee;
  }
  .admin_list_tble_tag tr:hover{
    filter: drop-shadow(0px 2px 6px #0002);
  }
   
  
  
  
  /* Pagination */
    .Admin_List_pgination{
      display: flex;
      justify-content: end;
      width: 85%;
      margin-top: 10px;
    }
  
    .Admin_List_pgination .pagination_view{
      width: 50px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      margin: 0px 5px 0px 5px;
      list-style: none;
    }
    .Admin_List_pgination li a{
      display: block;
      text-decoration: none;
      color: #777;
      font-weight: 600px;
      border-radius: 8px;
      border: 1px solid #00bcd4;
    }
    .Admin_List_pgination li.pagination_view:hover a, .Admin_List_pgination li.pagination_view.active a{
      background-color: #00bcd4;
      border: 1px solid #00bcd4;
      color: #fff;
    }
    .Admin_List_pgination li:first-child{
      font-weight: 700;
      font-size: 20px;
    }
    .Admin_List_pgination li:last-child{
      font-weight: 700;
      font-size: 20px;
    }
  
  
    .admin_list_tble_tag  tr td .patient_status{
      text-decoration: none;
      color: #44aab4;
      background-color: #ddede6;
      border: 1px solid #ddede6;
      padding: 5px 0px;
      font-weight: bold;
      border-radius: 8px;
      transition: 0.3s ease-in-out;
      text-align: center;
    }
    .admin_list_tble_tag  tr td .patient_status:hover{
      box-shadow: 0px 3px 8px #0003;
    }
  /*  */
  
  
  
  
  
  
    
    .add_category_modal{
      margin-top: 5px;
      margin-left: 10px;
      display: flex;
      flex-direction: column;
    }
    .add_category_modal button{
      padding: 12px 43px;
      border-radius: 5px;
      margin-top: 20px;
      background-color: #29166f;
      color: white;
      border: 1px solid #29166f;
      display: flex;
      justify-content: center;
      align-self: center;
      font-size: large;
      transition: 0.3s ease-in-out;
    }
    .add_category_modal button:hover{
      background-color: #f1f5fc;
      color: #29166f;
    }
    .add_category_modal #refund_loader:hover{
      background-color: #29166f;
      color: white;
    }
    
  
  
  
  
  
  
  
  
    /* Add Primary Category */
    .category_name{
      margin-bottom: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .category_name .name_width{
      width: 98%;
    }
    .category_name .name_width .name_label{
      color: black;
      font-size: 15px;
      margin-bottom: 10px;
      margin-top: 10px;
    }
    .category_name .name_width .name_label .input{
      width: 100%;
      border: 0.11px solid gray;
      padding: 12px;
      border-radius: 8px;
    }
  
    .add_category_modal .categoryImage{
      display: flex;
    }
  
    .add_category_modal .categoryImage img{
      width: 130px;
      height: 130px;
      border-radius: 10px;
      margin-top: 20px;
      object-fit: cover;
    }
    .add_category_modal .categoryImage .prfilebtn{
      text-decoration: none;
      display: flex;
      align-items: center;
      align-self: flex-end;
      background-color: #999486;
      color: white;
      padding: 4px 6px 4px 6px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      transition: 0.3s ease-in-out;
    }
    .add_category_modal .categoryImage .prfilebtn:hover{
      background-color: #f1f5fc;
      color: #1a6aff;
    }