.main_view{
    background-color: #eaeef3;
    padding: 20px;
    width: 100%;
};
.all_kard{
    display: flex;
}
.displayblock{
    display: inline-block;
}



/* Card One */
.pend_doctor_card{
    width: 250px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 0px 3px rgba(32,32,32,.3);
    padding-top: 15px;
    padding-left: 15px;
    padding-bottom: 24px;
    display: flex;
    flex-direction: row;
    text-align: start;
    margin: 10px;
}
.pend_doctor_card img{
    width: 50px;
    height: 50px;
    border-radius: 100px;
    object-fit: cover;
}

.list_in_card{
    margin-left: 20px;
}
.list_in_card h4{
    margin-bottom: 5px;
}
.list_in_card h6{
    color: gray;
    font-size: 9px;
}
.list_in_card i{
    color: #1a6aff;
    font-size: 12px;
}
.list_in_card .star{
    margin-bottom: 8px;
}
/*  */







/* Card Two */
.doctor_cad_2{
    width: 300px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 0px 3px rgba(32,32,32,.3);
    padding-bottom: 15px;
    /* text-align: center; */
    margin: 10px;
}
.doctor_cad_2 img{
    width: 100%;
    height: 200px;
    /* border-radius: 50%; */
    object-fit: cover;
}
.list_in_cad_2{
    margin-top: 10px;
    margin-left: 15px;
}
.list_in_cad_2 h6{
    color: gray;
    font-size: 20px;
    margin-top: 2px;
}
.list_in_cad_2 h5{
    color: gray;
    font-size: 13px;
}
.list_in_cad_2 h4{
    font-size: 13px;
    color: #00bcd4;
}
.list_in_cad_2 i{
    color: #28a745;
    font-size: 16px;
}
.list_in_cad_2 .star{
    margin-top: 8px;
    margin-bottom: 30px;
}


.book_appo{
    display: flex;
    max-width: 100%;
    justify-content: center;
    margin-top: 15px;
}
 .book_appo a{
    text-decoration: none;
    color: #444;
    background-color: #fff;
    border: 1px solid orange;
    display: inline-block;
    width: 90%;
    text-align: center;
    padding: 7px 20px;
    font-weight: bold;
    border-radius: 3px;
    transition: 0.3s ease-in-out;
}
 .book_appo a:nth-child(1){
    border-color: #26a69a;
}
 .book_appo a:hover{
    box-shadow: 0px 3px 8px #0003;
}

.loader_doctor_list{
    width: 100%;
    display: flex;
    height: 80vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.loader_doctor_list p{
    margin-top: 10px;
}